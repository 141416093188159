import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

// Connects to data-controller="slim-select"
export default class extends Controller {
  connect() {
    new SlimSelect({
      select: this.element,
      settings: {
        searchPlaceholder: "Rechercher",
        searchText: "Aucun résultats"
      }
    })
    const selectField = document.querySelector("div.ssc-main")
    selectField.addEventListener("click", () => {
      selectField.classList.add("ssc-main-focus")
    })
    selectField.addEventListener('blur', () => {
      selectField.classList.remove("ssc-main-focus")
    })
  }
}
